import {SET_GROUP_BY_FILTER} from "../../Actions/ActionConstants";

const GroupByFilterListReducer = (state = null, action) => {
    if (action.type === SET_GROUP_BY_FILTER) {
        return action.data;
    } else {
        return state;
    }
};
export default GroupByFilterListReducer

import { GET_CRS } from "../../Actions/ActionConstants";

function CRsReducer(state=null,action){
    switch (action.type) {
        case GET_CRS:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default CRsReducer
import { GET_GIs } from "../../Actions/ActionConstants";

function GIsReducer(state=null,action){
    switch (action.type) {
        case GET_GIs:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default GIsReducer
import {SET_SPARES_SALES_PERSON_LIST} from "../../Actions/ActionConstants";

const SalesPersonListReducer = (state = null, action) => {
    if (action.type === SET_SPARES_SALES_PERSON_LIST) {
        return action.data;
    } else {
        return state;
    }
};
export default SalesPersonListReducer

import { GET_UTM_SOURCES } from "../../Actions/ActionConstants";


function utmSourcesReducer(state=null,action){
    switch (action.type) {
        case GET_UTM_SOURCES:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default utmSourcesReducer
import { SET_TOKEN } from "../../Actions/ActionConstants";

function tokenReducer(state=null,action){
    switch (action.type) {
        case SET_TOKEN:
                return action.data;
        default:
            return state;
    }
}
export default tokenReducer
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
function PrivateRoute({ component: Component,token, ...rest }) {
  const isAuthenticated = token?true:false

  return (
    <Route
      {...rest}
      render={props =>{
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }}
    />
  );
}

export default connect(
    (state) => {
      return {
       token:state.token
      }
    })(PrivateRoute)
  
/**
 * Created by zultanite on 05-11-2017.
 */
export const orderTypeConstant = {
  preventive: 100,
  general: 101,
  document: 102,
  insurance: 103,
};

export const insuranceComponentConstant = {
  parentInsuranceForm: 1,
  insuranceEstimate: 2,
  insuranceChecklist: 3,
  insuranceBill: 4,
  insuranceFiles: 5,
  insuranceDetails: 6,
  insuranceOrdersList: 7,
};

export const GMConstant = {
  CR_OBJ: 'FOBJ',
  INSURANCE_OBJ: 'IOBJ',
  SELLER_OBJ: 'SOBJ',
  INSURANCE_BILL_TYPE_CUSTOMER: 1,
  INSURANCE_BILL_TYPE_SURVEYOR: 2,
  BRAND_ID_MARUTI: 9,
  SEARCH_TIMEOUT: 1000,
  HOT_LEAD: 1,
  WARM_LEAD: 2,
  COLD_LEAD: 3,
  LABOUR_SERVICES: 1,
  LABOUR_PARTS: 0,
  USER_CR: 'CR',
  USER_L1: 'Lead Call Center',
  USER_L2: 'CCL2',
  USER_LEAD_MANAGER: 'Lead Manager',
  USER_ESCALATION_MANAGER: 'escalation manager',
  USER_ADMIN: 'admin',
  USER_BDE: 'BDE',
  USER_CI: 'CI',
  USER_GI: 'GI',
  USER_INSURANCE_SALESMAN: 'Insurance Salesman',
  USER_PARTNER: 'Partner',
  FLEET_DEAL_ID: 6,
  TRANSFERRED_TO_OPS: 800,
  TRANSFERRED_TO_OPS_ID: 9,
  DUPLICATE: 1300,
  TRANSFERRED_TO_OPS_WITH_FOLLOWUP: 1600,
  FOLLOW_UP: 500,
  NEW_LEAD_STATUS: 100,
  TEST_CR_ID: 23,
  LEAD_PIK_UP_DUE: 900,
  LEAD_WALKING_DUE: 1000,
  LEAD_CONVERTED: 1200,
  LEAD_CONSULT_TO_OPS: 600,
  LEAD_CONSULT_TO_OPS_ID: 7,
  INSURANCE_LEADS: 333,
  LEAD_UNANSWERED: 400,
  LEAD_DEAD: 700,
  LEAD_DEAD_ID: 8,
  NEW_CALL_WAIT: 15000,
  CITY_HYD: "Hyderabad",
  CITY_BAN: "Bengaluru",
  CITY_PUNE: "Pune",
  CITY_MUMBAI: "Mumbai",
  CITY_THANE: "Thane",
  CITY_NAVIMUMBAI: "Navi Mumbai",
  CITY_KALYAN: "Kalyan",
  GENERAL_LEAD: "general",
  ACCESSORIES_LEAD: "accessories",
  SERVICE_LEAD: "service",
  INSURANCE_LEAD: "insurance",
  RETAIL_INSURANCE_LEAD: "retail_insurance",
  INSURANCE_RENEW_LEAD: "insurance_renew",
  INSURANCE_CLAIM_LEAD: "insurance_claim",
  COMMERCIAL_LEAD: "commercial",
  KIFAYAT_LEAD: "kifayat",
  CASH_LEAD: "cash",
  LUXURY_LEAD: "luxury",
  SUBSCRIPTION_LEAD: "subscription",
  NEW_LEAD: "new lead",
  RETAIL_LEAD: "retail",
  DEAD_BY_CR: 2000,
  DEFAULT_LEAD_TYPE: 'general',
};

export const GMWebServiceType = {
  DENTINGPAINTING: 16,
  WHEELCARESERVICES: 21,
  CARCARESERVICES: 34,
};

export const OTPConstant = {
  PRE_REGISTRATION_OTP: '3',
  LOGIN_OTP: '4'
};

export const PaymentGateways = {
  1: 'PAYTM_GATEWAY',
  2: 'PAYTM',
  3: 'CASH',
  4: 'CHEQUE',
  5: 'PAYUMONEY_GATEWAY',
  6: 'INSTAMOJO_LINK',
  7: 'DEBIT_CREDIT',
  8: 'MOBIKWIK_GATEWAY'
};

export const escalationStatusCodeMapping = {
  1: "Open",
  2: "Waiting on Customer",
  3: "Waiting on Third Party",
  4: "Resolved",
  5: "Closed",
  6: "Assigned to CR",
  7: "Work Started",
  8: "Reopen"
}

export const QualityList = [
  {
    key: "Warm",
    label: "Warm",
  },
  {
    key: "Hot",
    label: "Hot",
  },
  {
    key: "Cold",
    label: "Cold",
  },
];

export const UnionTerritorries = [
  { name: "Andaman and Nicobar Islands", state: "Andaman and Nicobar Islands", id: 321 },
  { name: "Dadra and Nagar Haveli and Daman and Diu", state: "Dadra and Nagar Haveli and Daman and Diu", id: 322 },
  { name: "Jammu and Kashmir", state: "Jammu and Kashmir", id: 326 },
  { name: "Lakshadweep", state: "Lakshadweep", id: 323 },
  { name: "Chandigarh", state: "Chandigarh", id: 48 },
  { name: "Ladakh", state: "Ladakh", id: 325 },
  { name: "Pondicherry", state: "Pondicherry", id: 189 }, // Previous name of Puducherry
  { name: "Puducherry", state: "Puducherry", id: 189 },
  { name: 'Delhi', state: 'Delhi', id: 2 },
  { name: 'Goa', state: 'Goa', id: 320 },
];

export const paymentStatusConstant = {
  "-1": "Payment Failed",
  "1" : "Payment Successful",
  "2" : "Refunded"
}

export const garageStatus = {
  "1": "Open",
  "0": "Close"
}

export const sparesTypeLeadList = ['spares_parts', 'spares_franchise', 'spares_gopreneur', 'Spares_b2b'];
export const generalTypeLeadList = [];
export const accessoriesTypeLeadList = ["accessories"];

export const timeSlots = [
  { to: "8:00", from: "8:30" },
  { to: "8:30", from: "9:00" },
  { to: "9:00", from: "9:30" },
  { to: "9:30", from: "10:00" },
  { to: "10:00", from: "10:30" },
  { to: "10:30", from: "11:00" },
  { to: "11:00", from: "11:30" },
  { to: "11:30", from: "12:00" },
  { to: "12:00", from: "12:30" },
  { to: "12:30", from: "13:00" },
  { to: "13:00", from: "13:30" },
  { to: "13:30", from: "14:00" },
  { to: "14:00", from: "14:30" },
  { to: "14:30", from: "15:00" },
  { to: "15:00", from: "15:30" },
  { to: "15:30", from: "16:00" },
  { to: "16:00", from: "16:30" },
  { to: "16:30", from: "17:00" },
  { to: "17:00", from: "17:30" },
  { to: "17:30", from: "18:00" },
  { to: "18:00", from: "18:30" },
  { to: "18:30", from: "19:00" },
]

export const MALAYSIA_COUNTRY_CODE = "MY";
export const INDIA_COUNTRY_CODE = "IN";

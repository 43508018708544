import {SET_PAYEMNT_STATUS_FILTER_LIST} from "../../Actions/ActionConstants";

const PaymentStatusListReducer = (state = null, action) => {
    if (action.type === SET_PAYEMNT_STATUS_FILTER_LIST) {
        return action.data;
    } else {
        return state;
    }
};
export default PaymentStatusListReducer

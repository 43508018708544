import { SET_LEAD_ESTIMATE_LOADER } from "../../Actions/ActionConstants";

function leadServiceEstimateLoaderReducer(state = false, action) {
    switch (action.type) {
        case SET_LEAD_ESTIMATE_LOADER:
            if (action.data) { return action.data; }
            else { return state }
        default:
            return state;
    }
}
export default leadServiceEstimateLoaderReducer;
import { GET_SPECIAL_OFFERS_AND_DEALS } from "../../Actions/ActionConstants";

function getSpecialOffersAndDeals(state = null, action) {
    switch (action.type) {
        case GET_SPECIAL_OFFERS_AND_DEALS:
            if (action.data) {
                return action.data;
            }
            else { return null }
        default:
            return state;
    }
}
export default getSpecialOffersAndDeals
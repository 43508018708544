import { SET_AMC_PURCHASE_STATUS } from "../../Actions/ActionConstants";

function getAmcPurchaseStatus(state = null, action) {
    switch (action.type) {
        case SET_AMC_PURCHASE_STATUS:
            if (action.data) {
                return action.data;
            }
            else { return null }
        default:
            return state;
    }
}
export default getAmcPurchaseStatus
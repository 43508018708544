import { SET_INCOMING_CALL_INFO } from "../../Actions/ActionConstants";

function incomingCallInfo(state = null, action) {
    switch (action.type) {
        case SET_INCOMING_CALL_INFO:
            if (action.data) { 
                return action.data; 
            }
            else { return null }
        default:
            return state;
    }
}
export default incomingCallInfo
import { TOGGLE_LOADER } from "../../Actions/ActionConstants";

function LoaderReducer(state=null,action){
    switch (action.type) {
        case TOGGLE_LOADER:
                return action.data
        default:
            return state;
    }
}
export default LoaderReducer
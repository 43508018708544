import { GET_ACTION_DETAILS } from "../../Actions/ActionConstants";

function actionDetailsReducer(state=null,action){
    switch (action.type) {
        case GET_ACTION_DETAILS:
            const { data } = action
                return { ...state, ...data }
        default:
            return state;
    }
}
export default actionDetailsReducer
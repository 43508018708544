import { get, post } from "../Services/FetchWrapper";
import { GMConstant, sparesTypeLeadList, accessoriesTypeLeadList } from "../Constants/constant";
import moment from "moment";
import { callCustomer, callCustomerByBDE } from "../Components/services";
import ValidityJSON from "../Shared/ValidityJSON/Validity.json";
var user = "";
let priorityTimeout = null;
export function getLeadsFromServer(url) {
	return new Promise((resolve, reject) => {
		get(`${url}`)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getCCBannerInfoFromServer(url) {
	return new Promise((resolve, reject) => {
		get(`${url}`)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getCCReportingList(url) {
	return new Promise((resolve, reject) => {
		get(`${url}`)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function setUserData(user_name) {
	user = user_name;
}

export function reducerForAutoComplete(
	arrayToBeReduced,
	keyToCompare,
	valueToCompare
) {
	return arrayToBeReduced.reduce(
		(a, v) => (v[keyToCompare] === valueToCompare ? v : a),
		null
	);
}

export function setLocalStorage(key, data) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function getDataFromLocalStorage(key) {
	if (localStorage.getItem(key)) {
		return JSON.parse(localStorage.getItem(key));
	} else {
		return null;
	}
}

export function getDataFromSessionStorage(key) {
	if (sessionStorage.getItem(key)) {
		return JSON.parse(sessionStorage.getItem(key));
	} else {
		return null;
	}
}

export function checkIfKeyInLocalStorage(key) {
	if (localStorage.getItem(key)) {
		return true;
	} else {
		return false;
	}
}

export function getLeadListFilterString(
	sorting_order,
	paginator_obj,
	filterData
) {
	let query_params;
	// console.log(filterData);
	if (filterData === undefined) {
		window.location.reload();
	}
	if (sorting_order === null && paginator_obj.excel === 1) {
		query_params = `?excel=${paginator_obj.excel}`;
	} else {
		query_params = `?order_by=${sorting_order.order_by}&direction=${sorting_order.direction}`;
		for (let key in paginator_obj) {
			query_params = query_params.concat(`&${key}=${paginator_obj[key]}`);
		}
	}
	if (filterData.source_name.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.source_name.length; i++) {
			temp.push(filterData.source_name[i].id);
		}
		query_params = query_params.concat(`&source_ids=${temp.toString()}`);
	}
	if (filterData.location.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.location.length; i++) {
			temp.push(filterData.location[i].id);
		}
		query_params = query_params.concat(`&location_ids=${temp.toString()}`);
	}
	if (filterData.assign_to.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.assign_to.length; i++) {
			temp.push(filterData.assign_to[i].id);
		}
		if (!filterData.search_for_converted_by) {
			query_params = query_params.concat(`&assign_to=${temp.toString()}`);
		} else {
			query_params = query_params.concat(`&converted_by=${temp.toString()}`);
		}
	}
	if (filterData.status_name.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.status_name.length; i++) {
			temp.push(filterData.status_name[i].status_id);
		}
		query_params = query_params.concat(`&status_ids=${temp.toString()}`);
	}
	if (filterData.lead_type.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.lead_type.length; i++) {
			temp.push(filterData.lead_type[i].lead_type);
		}
		query_params = query_params.concat(`&lead_types=${temp.toString()}`);
	}
	if (filterData.tags && filterData.tags.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.tags.length; i++) {
			temp.push(filterData.tags[i].name);
		}
		query_params = query_params.concat(`&tags=${temp.toString()}`);
	}
	if (filterData.utm_source && filterData.utm_source.length !== 0) {
		let temp = [];
		for (let i = 0; i < filterData.utm_source.length; i++) {
			temp.push(filterData.utm_source[i].id);
		}
		query_params = query_params.concat(`&utm_source_ids=${temp.toString()}`);
	}
	if (filterData.quality) {
		query_params = query_params.concat(`&lead_quality=${filterData.quality}`);
	}
	if (filterData.short_date) {
		query_params = query_params.concat(`&date_filter=${filterData.short_date}`);
	}
	if (filterData.start_date) {
		query_params = query_params.concat(`&start_date=${filterData.start_date}`);
	}
	if (filterData.end_date) {
		query_params = query_params.concat(`&end_date=${filterData.end_date}`);
	}
	if (filterData.is_language_barrier) {
		query_params = query_params.concat(
			`&is_language_barrier=${filterData.is_language_barrier}`
		);
	}
	if (filterData.lead_payment_status !== null) {
		query_params = query_params.concat(
			`&lead_payment_status=${filterData.lead_payment_status}`
		);
	}
	return query_params;
}

export function getCCReportingListFilters(paginationObj, filterObj) {
	let query_params;
	query_params = `?page_limit=${paginationObj.page_limit}`;
	for (let keys in paginationObj) {
		if (keys !== "page_limit") {
			query_params = query_params.concat(`&${keys}=${paginationObj[keys]}`);
		}
	}
	if (filterObj.assign_to && filterObj.assign_to.length) {
		let temp = [];
		for (let i = 0; i < filterObj.assign_to.length; i++) {
			temp.push(filterObj.assign_to[i].id);
		}
		query_params = query_params.concat(`&assign_to=${temp.toString()}`);
	}
	if (filterObj.location && filterObj.location.length) {
		let temp = [];
		for (let i = 0; i < filterObj.location.length; i++) {
			temp.push(filterObj.location[i].id);
		}
		query_params = query_params.concat(`&location_ids=${temp.toString()}`);
	}
	if (filterObj.lead_type && filterObj.lead_type.length) {
		let temp = [];
		for (let i = 0; i < filterObj.lead_type.length; i++) {
			temp.push(filterObj.lead_type[i].lead_type);
		}
		query_params = query_params.concat(`&lead_type=${temp.toString()}`);
	}
	if (filterObj.source_name && filterObj.source_name.length) {
		let temp = [];
		for (let i = 0; i < filterObj.source_name.length; i++) {
			temp.push(filterObj.source_name[i].id);
		}
		query_params = query_params.concat(`&source_ids=${temp.toString()}`);
	}
	if (filterObj.start_date) {
		query_params = query_params.concat(`&start_date=${filterObj.start_date}`);
	}
	if (filterObj.end_date) {
		query_params = query_params.concat(`&end_date=${filterObj.end_date}`);
	}
	if (filterObj.group_by) {
		query_params = query_params.concat(`&group_by=${filterObj.group_by}`);
	}
	return query_params;
}

export function isLeadManagerOrAdmin() {
	if (localStorage.getItem("user_type")) {
		let user = localStorage.getItem("user_type");
		return (
			user === GMConstant.USER_LEAD_MANAGER || user === GMConstant.USER_ADMIN
		);
	} else {
		return false;
	}
}

export function isEscalationManager() {
	if (localStorage.getItem("user_type")) {
		let user = localStorage.getItem("user_type");
		return user === GMConstant.USER_ESCALATION_MANAGER;
	} else {
		return false;
	}
}

export function MultiSelectMapperFunction(data, name) {
	return data.map((d, i) => ({
		value: d,
		label: d[name],
	}));
}

export function isL2() {
	return user === GMConstant.USER_L2;
}

export function isPartner() {
	if (user === GMConstant.USER_PARTNER) {
		return true;
	} else {
		return false;
	}
}

export function isL1() {
	return user === GMConstant.USER_L1;
}
export function isBDE() {
	return user === GMConstant.USER_BDE;
}

export function isInsuranceOrCashLead(leadType) {
	return (
		leadType === GMConstant.INSURANCE_LEAD ||
		leadType === GMConstant.RETAIL_INSURANCE_LEAD ||
		leadType === GMConstant.INSURANCE_RENEW_LEAD ||
		leadType === GMConstant.INSURANCE_CLAIM_LEAD ||
		leadType === GMConstant.CASH_LEAD
	);
}
export function isInsuranceLead(leadType) {
	return (
		leadType === GMConstant.INSURANCE_LEAD ||
		leadType === GMConstant.RETAIL_INSURANCE_LEAD ||
		leadType === GMConstant.INSURANCE_RENEW_LEAD ||
		leadType === GMConstant.INSURANCE_CLAIM_LEAD
	);
}
export function isCashLead(leadType) {
	return leadType === GMConstant.CASH_LEAD;
}

export function isTtoCtoAndTTOwfup(statusId) {
	return (
		statusId === GMConstant.TRANSFERRED_TO_OPS ||
		statusId === GMConstant.LEAD_CONSULT_TO_OPS ||
		statusId === GMConstant.TRANSFERRED_TO_OPS_WITH_FOLLOWUP
	);
}

export function isCommercialLead(leadType) {
	return leadType === GMConstant.COMMERCIAL_LEAD || leadType === GMConstant.KIFAYAT_LEAD;
}

export function Bang_Hyd_Mumbai_Thane_Pune_Kalyan_NaviMum(loc) {
	if (
		loc === GMConstant.CITY_MUMBAI ||
		loc === GMConstant.CITY_THANE ||
		loc === GMConstant.CITY_NAVIMUMBAI ||
		loc === GMConstant.CITY_KALYAN ||
		loc === GMConstant.CITY_HYD ||
		loc === GMConstant.CITY_BAN ||
		loc === GMConstant.CITY_PUNE
	) {
		return true;
	} else {
		return false;
	}
}

export function checkForLeadFilters() {
	return !!localStorage.getItem("filters");
}

export const checkIfanyFilterApplied = () => {
	if (localStorage.getItem("filters")) {
		let filterData = JSON.parse(localStorage.getItem("filters"));
		let filterFound = false;
		if (
			filterData.quality ||
			filterData.utm_source ||
			filterData.short_date ||
			filterData.is_language_barrier
		) {
			filterFound = true;
		}
		if (
			filterData.source_name.length !== 0 ||
			filterData.status_name.length !== 0 ||
			filterData.location.length !== 0 ||
			filterData.lead_type.length !== 0
		) {
			filterFound = true;
		}
		if (!isL1()) {
			if (filterData.assign_to.length === 0) {
				filterFound = true;
			}
		}
		return filterFound;
	} else {
		return false;
	}
};

export function getUserDataFromLocal() {
	if (localStorage.getItem("user_info")) {
		return JSON.parse(localStorage.getItem("user_info"));
	} else {
		return null;
	}
}

const totalAmount = (data) =>
	data
		? data.final_amnt
			? data.discount_amnt
				? data.discount_amnt + data.final_amnt
				: data.final_amnt
			: "NA"
		: "NA";

const calculateFinalAmount = (data) => {
	const total = totalAmount(data);
	if (total !== "NA") {
		if (data.go_app_money_used) {
			return total - data.go_app_money_used + data.slot_amount;
		} else {
			return data.slot_amount
				? data.final_amnt + data.slot_amount
				: data.final_amnt;
		}
	} else {
		return "NA";
	}
};
let capitalize = (name) => {
	if (name) {
		let nameSplit = name.split(" ");
		let nameSplitWithCapitalize = nameSplit.map((value) => {
			return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
		});
		return nameSplitWithCapitalize.join(" ");
	} else {
		return "NA";
	}
};
let getOfferString = (lead) => {
	const goAppMoneyAmount = getActiveCountryCode() === "MY" ? 20 : 300;
	if (lead.category_template_text) {
		return lead.category_template_text;
	} else {
		const offerMessage = `Book Today & Get ${getCurrencySymbolCountryWise()} ${goAppMoneyAmount} GoApp Money Cashback`;
		if (lead.coupon_id) {
			if (lead.coupon_id == "1544060") {
				return offerMessage;
			} else {
				return "NA";
			}
		} else {
			return offerMessage;
		}
	}
};

export function copyToClipBoardAccessories(_lead_data, copy_from_list) {
	
	const btn = document.createElement("BUTTON");
	btn.setAttribute("data-clipboard-action", "copy");
	const secretInfo = `${
		_lead_data.is_subscription && _lead_data.is_subscription === 1
			? "*Subscribed User*"
			: ""
	}
Name : *${_lead_data.name ? capitalize(_lead_data.name) : "NA"}*
Mobile : *${_lead_data.mobile}*
Alternate Mobile: ${
		_lead_data.alternate_mobile ? _lead_data.alternate_mobile : "NA"
	}
Address : ${_lead_data.address ? _lead_data.address : "NA"}

Map Location : ${_lead_data.map_location ? _lead_data.map_location : "NA"}
*Location: ${_lead_data.location ? _lead_data.location : "NA"}*
${_lead_data.lead_type === "bike" ? "Bike" : "Car"} Name : *${
		_lead_data.car_name && _lead_data.car_name !== ""
			? _lead_data.car_name.trim()
			: "NA"
	}*
${_lead_data.lead_type === "bike" ? "Bike" : "Car"} Variant : ${
		_lead_data.car_variant ? _lead_data.car_variant : "NA"
	}
Reg No. : ${_lead_data.registration_no ? _lead_data.registration_no : "NA"}

Arrival Mode: *${
		_lead_data.arrival_mode_type ? _lead_data.arrival_mode_type : "NA"
	}*
Installation Date: *${
		_lead_data.expected_date && _lead_data.expected_date !== ""
			? moment(_lead_data.expected_date).format("DD-MMM-Y")
			: "NA"
	}*
Installation Time: *${
		_lead_data.expected_time && _lead_data.expected_time !== ""
			? moment(_lead_data.expected_time, "HH:mm").format("hh:mm A")
			: "NA"
	}*

Assign CR : ${
		_lead_data.assign_to_cr_name ? _lead_data.assign_to_cr_name : "NA"
	}
Assign CE : ${
		copy_from_list
			? _lead_data.assign_to_l1_name
				? _lead_data.assign_to_l1_name
				: "NA"
			: _lead_data.assign_to_l1_name
			? _lead_data.lead_type === "general" && isL1()
				? getUserDataFromLocal().name
				: _lead_data.assign_to_l1_name
			: "NA"
	}
Assign GI : ${
		_lead_data.assign_to_gi_name ? _lead_data.assign_to_gi_name : "NA"
	}
Lead Status : ${_lead_data.status_name ? _lead_data.status_name : "NA"}
Garage : *${_lead_data.garage_name ? _lead_data.garage_name : "NA"}*
Assign BDM : *${_lead_data.assign_to_bdm_name ? _lead_data.assign_to_bdm_name : "NA"}*
Retailer : *${_lead_data.assign_to_retailer_name ? _lead_data.assign_to_retailer_name : "NA"}*
Source: ${
		_lead_data.source_name && _lead_data.source_name !== ""
			? _lead_data.source_name
			: "NA"
	}
Order ID: ${_lead_data.order_id ? _lead_data.order_id : "NA"}

Product Requirement: ${_lead_data.estimate_remarks ? _lead_data.estimate_remarks : "NA"}

*Workshops - Use Customer Location ( DON'T CALL FOR LOCATION ) 👇 : ${
		_lead_data.longitude && _lead_data.latitude
			? `http://www.google.com/maps/place/${_lead_data.latitude},${_lead_data.longitude}`
			: "NA"
	}*
`;
	btn.id = "copybtn";
	btn.setAttribute("data-clipboard-text", secretInfo);
	document.body.appendChild(btn);
	const clipboard = new window.ClipboardJS("#copybtn");
	clipboard.on("success", (e) => {
		console.info("Action:", e.action);
		//e.clearSelection();
	});
	clipboard.on("error", (e) => {
		console.error({ e });
		console.error("Trigger:", e.trigger);
	});
	btn.click();
	document.body.removeChild(btn);
	console.log("copy compeleted");
}

export function copyToClipBoard(_lead_data, copy_from_list, milesData, isRequestDirectPickup) {
	//console.log(JSON.stringify(_lead_data));
	const btn = document.createElement("BUTTON");
	btn.setAttribute("data-clipboard-action", "copy");
	const secretInfo = `${_lead_data.direct_garage_assign ? '*🚨 Pickup Directly Assigned to 3 Workshops - Get it accepted 🚨*':""}
	${milesData && milesData.isMiles ? '*Imp : Miles Order*' : ""}
	${_lead_data.is_subscription && _lead_data.is_subscription === 1 ? "*Subscribed User*" : ""}
	Name : *${_lead_data.name ? capitalize(_lead_data.name) : "NA"}*
	${isRequestDirectPickup ? "" : `Mobile : *${_lead_data.mobile}*`}
	${isRequestDirectPickup ? "" : `Alternate Mobile: ${_lead_data.alternate_mobile ? _lead_data.alternate_mobile : "NA"}`}
	Address : ${_lead_data.address ? _lead_data.address : "NA"}

	Map Location : ${_lead_data.map_location ? _lead_data.map_location : "NA"}
	*Location: ${_lead_data.location ? _lead_data.location : "NA"}*
	${_lead_data.lead_type === "bike" ? "Bike" : "Car"} Name : *${
		_lead_data.car_name && _lead_data.car_name !== "" ? _lead_data.car_name.trim() : "NA"
	}*
	${_lead_data.lead_type === "bike" ? "Bike" : "Car"} Variant : ${_lead_data.car_variant ? _lead_data.car_variant : "NA"}
	Reg No. : ${_lead_data.registration_no ? _lead_data.registration_no : "NA"}

	Estimate Remarks: *${
		copy_from_list
			? _lead_data.estimate_remarks
				? _lead_data.estimate_remarks
				: "No Remarks"
			: _lead_data.lead_estimate
				? _lead_data.lead_estimate.estimate_remarks
					? _lead_data.lead_estimate.estimate_remarks
					: "No Remarks"
				: "No Estimate"
	}*

	*WhatsApp Offer : ${getOfferString(_lead_data)}*

	Lead Estimate: ${getLeadEstimateString(_lead_data)}
	*Amount Before Discount : ${copy_from_list ? totalAmount(_lead_data) : totalAmount(_lead_data.lead_estimate)}*
	*Amount After Discount : ${
		copy_from_list
			? _lead_data.final_amnt
				? _lead_data.final_amnt
				: "NA"
			: _lead_data.lead_estimate && _lead_data.lead_estimate.final_amnt
				? calculateFinalAmount(_lead_data.lead_estimate)
				: "NA"
	}*

	*Slot Surcharge/Rebate: ${
		!copy_from_list 
			? _lead_data.lead_estimate && _lead_data.lead_estimate.slot_amount || 0
			: _lead_data.slot_amount || 0
	}*
	*Safety and Warranty Fees: ${_lead_data.additional_amount || 0}*
	*GoApp Money Applied: ${
		!copy_from_list
			? _lead_data.lead_estimate && _lead_data.lead_estimate.go_app_money_used || 0
			: _lead_data.go_app_money_used || 0
	}*
	${
	milesData && milesData.isMiles ? `
	*Miles Member : YES*
	*Miles Discount Applied : ${getCurrencySymbolCountryWise()} ${milesData.totalMilesDiscount}*
	` : ``
	}
	*Coupon Code : ${
		copy_from_list
			? _lead_data.coupon_name
				? _lead_data.coupon_name
				: "NA"
			: _lead_data.lead_estimate && _lead_data.lead_estimate.coupon_name
				? _lead_data.lead_estimate.coupon_name
				: "NA"
	}*
	Arrival Mode: *${_lead_data.arrival_mode_type ? _lead_data.arrival_mode_type : "NA"}*
	Date of visit: *${
		_lead_data.expected_date && _lead_data.expected_date !== ""
			? moment(_lead_data.expected_date).format("DD-MMM-Y")
			: "NA"
	}*
	Preferred Time: *${
		_lead_data.expected_time && _lead_data.expected_time !== ""
			? moment(_lead_data.expected_time, "HH:mm").format("hh:mm A")
			: "NA"
	}*
	${_lead_data.direct_garage_assign ? "Default " : ""}CR : ${_lead_data.assign_to_cr_name ? _lead_data.assign_to_cr_name : "NA"}
	Assign CE : ${
		copy_from_list
			? _lead_data.assign_to_l1_name
				? _lead_data.assign_to_l1_name
				: "NA"
			: _lead_data.assign_to_l1_name
				? _lead_data.lead_type === "general" && isL1()
					? getUserDataFromLocal().name
					: _lead_data.assign_to_l1_name
				: "NA"
	}
	Lead Status : ${_lead_data.status_name ? _lead_data.status_name : "NA"}
	${_lead_data.direct_garage_assign ? "Default " : ""}Garage : *${_lead_data.garage_name ? _lead_data.garage_name : "NA"}*
	Source: ${_lead_data.source_name && _lead_data.source_name !== "" ? _lead_data.source_name : "NA"}
	Insurance Company: ${
		_lead_data.insurance_company_name
			? _lead_data.insurance_company_name
			: _lead_data.insurance && _lead_data.insurance.company_name
				? _lead_data.insurance.company_name
				: "NA"
	}
	Order ID: ${_lead_data.order_id ? _lead_data.order_id : "NA"}
	Razorpay Link: ${_lead_data.razorpay_link ? _lead_data.razorpay_link : "NA"}

	${
		_lead_data.is_subscription || ["general", "detailing", "accessories", "service"].indexOf(_lead_data.lead_type) === -1
			? `*Click to open order: ${
					_lead_data.lead_type === "bike"
						? "NA"
						: `http://leads.gomechanic.in/follow-up/add/${_lead_data.lead_id}`
				}*`
			: ""
	}
            
	${
		!copy_from_list && _lead_data.amount
			? `*${getCurrencySymbolCountryWise()} ${_lead_data.amount} Paid by Customer*`
			: ""
	}

	*Workshops - Use Customer Location ( DON'T CALL FOR LOCATION ) 👇 : ${
		_lead_data.longitude && _lead_data.latitude
			? `http://www.google.com/maps/place/${_lead_data.latitude},${_lead_data.longitude}`
			: "NA"
	}*
    `;
	btn.id = "copybtn";
	btn.setAttribute("data-clipboard-text", secretInfo);
	document.body.appendChild(btn);
	const clipboard = new window.ClipboardJS("#copybtn");
	clipboard.on("success", (e) => {
		console.info("Action:", e.action);
		//e.clearSelection();
	});
	clipboard.on("error", (e) => {
		console.error({ e });
		console.error("Trigger:", e.trigger);
	});
	btn.click();
	document.body.removeChild(btn);
	console.log("copy compeleted");
}

export function copyNo(data) {
	const btn = document.createElement("BUTTON");
	btn.setAttribute("data-clipboard-action", "copy");
	btn.id = "copybtn";
	const secretInfo = data;
	btn.setAttribute("data-clipboard-text", secretInfo);
	document.body.appendChild(btn);
	const clipboard = new window.ClipboardJS("#copybtn");
	clipboard.on("success", (e) => {
		console.info("Action:", e.action);
		//e.clearSelection();
	});
	clipboard.on("error", (e) => {
		console.error({ e });
		console.error("Trigger:", e.trigger);
	});
	btn.click();
	document.body.removeChild(btn);
	console.log("copy compeleted");
}

function getArrayToString(array_, key_name_to_get) {
	let str = "";
	if (array_.length) {
		str = array_.map((item) => str.concat(item[key_name_to_get]));
	}
	return str;
}

export function copySparesLeadToClipBoard(_lead_data, remarks) {
	const btn = document.createElement("BUTTON");
	btn.setAttribute("data-clipboard-action", "copy");
	const sparesSecretInfo = `
Name : *${_lead_data.name ? _lead_data.name : "NA"}*
Mobile : *${_lead_data.mobile}*
Alternate Mobile: ${
		_lead_data.alternate_mobile ? _lead_data.alternate_mobile : "NA"
	}
Address : ${_lead_data.address ? _lead_data.address : "NA"}

Map Location : ${_lead_data.map_location ? _lead_data.map_location : "NA"}
*Location: ${_lead_data.location ? _lead_data.location : "NA"}*

Business Name : ${_lead_data.business_name ? _lead_data.business_name : "NA"}
Follow up Date: *${
		_lead_data.followup_date && _lead_data.followup_date !== ""
			? moment(_lead_data.followup_date).format("DD-MMM-Y")
			: "NA"
	}*
Follow up Time: *${
		_lead_data.followup_time && _lead_data.followup_time !== ""
			? moment(_lead_data.followup_time, "HH:mm").format("hh:mm A")
			: "NA"
	}*
            
Meeting Date: *${
		_lead_data.expected_date && _lead_data.expected_date !== ""
			? moment(_lead_data.expected_date).format("DD-MMM-Y")
			: "NA"
	}*
Meeting Time: *${
		_lead_data.expected_time && _lead_data.expected_time !== ""
			? moment(_lead_data.expected_time, "HH:mm").format("hh:mm A")
			: "NA"
	}*

Business Types: *${getArrayToString(_lead_data.business_types || [], "name")}*
Car Brands: *${getArrayToString(_lead_data.car_brands || [], "name")}*
Spares Brands: *${getArrayToString(
		_lead_data.spares_brands || [],
		"sku_brand"
	)}*
Other Spares Brand Name: ${
		_lead_data.other_part_type_name ? _lead_data.other_part_type_name : "NA"
	}
Part Types: ${_lead_data.part_types ? _lead_data.part_types : "NA"}

UTM Source: ${_lead_data.utm_source_name ? _lead_data.utm_source_name : "NA"}
Assign Salesman : ${
		_lead_data.assign_to_sp_name ? _lead_data.assign_to_sp_name : "NA"
	}
Lead Status : ${_lead_data.status_name ? _lead_data.status_name : "NA"}
Source: ${
		_lead_data.source_name && _lead_data.source_name !== ""
			? _lead_data.source_name
			: "NA"
	}
Remarks : ${remarks ? remarks : "NA"}
Order ID: ${_lead_data.order_id ? _lead_data.order_id : "NA"}`;
	btn.id = "copySparesBtn";
	btn.setAttribute("data-clipboard-text", sparesSecretInfo);
	document.body.appendChild(btn);
	const clipboard = new window.ClipboardJS("#copySparesBtn");
	clipboard.on("success", (e) => {
		console.info("Action:", e.action);
		//e.clearSelection();
	});
	clipboard.on("error", (e) => {
		console.error({ e });
		console.error("Trigger:", e.trigger);
	});
	btn.click();
	document.body.removeChild(btn);
	console.log("copy compeleted");
}

export const clearClipBoard = () => {
	const btn = document.createElement("BUTTON");
	btn.setAttribute("data-clipboard-action", "copy");
	btn.id = "clearCopybtn";
	const secretInfo = " ";
	btn.setAttribute("data-clipboard-text", secretInfo);
	document.body.appendChild(btn);
	const clipboard = new window.ClipboardJS("#clearCopybtn");
	clipboard.on("success", (e) => {
		console.info("Action:", e.action);
		//e.clearSelection();
	});
	clipboard.on("error", (e) => {
		console.error({ e });
		console.error("Trigger:", e.trigger);
	});
	btn.click();
	document.body.removeChild(btn);
	console.log("clear copydata compeleted");
};

const getLeadEstimateString = (_lead_data) => {
	if (_lead_data.lead_estimate) {
		let common_estimate_list = [];
		if (
			_lead_data.lead_estimate.service &&
			_lead_data.lead_estimate.service.length > 0
		) {
			_lead_data.lead_estimate.service.forEach((service) => {
				if (service.comment && service.comment !== "") {
					common_estimate_list.push(
						`(${service.quantity}) - ${service.service_name} (${service.comment}) - ${getCurrencySymbolCountryWise()} ${service.total}`
					);
				} else {
					common_estimate_list.push(
						`(${service.quantity}) - ${service.service_name} - ${getCurrencySymbolCountryWise()} ${service.total}`
					);
				}
			});
		}

		if (
			_lead_data.lead_estimate.package &&
			Object.keys(_lead_data.lead_estimate.package).length > 0
		) {
			common_estimate_list.push(
				`(${1}) - ${_lead_data.lead_estimate.package.package_name}`
			);
		}

		return common_estimate_list.join("\n\t\t\t");
	} else {
		return "No Estimate Available";
	}
};

export const getNextPriorityLead = (get_next, props) => {
	if (priorityTimeout) {
		clearTimeout(priorityTimeout);
	}
	if (get_next) {
		priorityTimeout = setTimeout(() => {
			get("priority-lead/")
				.then((res) => {
					console.log(res);
					if (res.data && res.status === true) {
						console.log(res);
						navigateToLeadDetailsPage(res.data, props);
						if (isBDE()) {
							callCustomerByBDE(res.data.mobile);
						} else {
							callCustomer(res.data.mobile);
						}
					} else if(res.message) {
						alert(res.message);
					} else {
						alert("No Lead for auto Dialer, please process manually");
					}
				})
				.catch((error) => console.log(error));
		}, 5000);
	} else {
		console.log("clear timer");
		if (priorityTimeout) {
			clearTimeout(priorityTimeout);
		}
	}
};

export const sendFrontEndLogs = async (payload) => {
	console.log("776 ", payload);
	const res = await post("frontend-logs/", payload);
	return res ? res : null;
};

export const getLeadActionList = (master_oid, _id) => {
	return new Promise((resolve, reject) => {
		get(`actions/${master_oid}/master_oid/`)
			.then((action_data) => {
				resolve(action_data.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updateLoginStatusOnServer = (obj) => {
	if (getDataFromLocalStorage("user_info")) {
		obj.user_id = getDataFromLocalStorage("user_info").user_id;
		post(`login/activity/`, obj)
			.then(() => {})
			.catch((err) => console.log(err));
	}
};

export const formatGarageList = (garages) => {
	let dataGarages = {direct_pickup: [], non_direct_pickup: []};
	const formatData = (data) => data.map((garage) => ({
		...garage,
		custom_label: `${garage.gm_name} ${
			garage.location ? `[${garage.location}]` : null
		} ${
			garage.distance
				? `[${(garage.distance.calculated / 1000).toFixed(2)} K.M.]`
				: ""
		}`,
	}));
	// if(garages && garages.data && garages.data.length) {
	// 	dataGarages.non_direct_pickup = formatData(garages) || [];
	// } else 
	if(garages && garages.direct_pickup && garages.direct_pickup.length) {
		dataGarages.direct_pickup = formatData(garages.direct_pickup || []);
	}
	if(garages && garages.non_direct_pickup && garages.non_direct_pickup.length) {
		dataGarages.non_direct_pickup = formatData(garages.non_direct_pickup || []);
	}
	return dataGarages;
}
	

export const formatAgentList = (agent_list) =>
	agent_list.map((agent) => ({
		...agent,
		custom_label: `${
			agent.id ? `${agent.name} - ${agent.mobile}` : `${agent.name}`
		}`,
	}));

const distanceCalculator = (lat1, lon1, lat2, lon2) => {
	const dLat = ((parseFloat(lat2) - parseFloat(lat1)) * Math.PI) / 180.0;
	const dLon = ((parseFloat(lon2) - parseFloat(lon1)) * Math.PI) / 180.0;
	// convert to radians
	lat1 = (parseFloat(lat1) * Math.PI) / 180.0;
	lat2 = (parseFloat(lat2) * Math.PI) / 180.0;
	// apply formulae
	const mid_val =
		Math.pow(Math.sin(dLat / 2), 2) +
		Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
	const rad = 6371;
	const ans_rad = 2 * Math.asin(Math.sqrt(mid_val));
	return rad * ans_rad;
};

export const getNearestCity = (
	lat,
	long,
	citiesList,
	default_shortest_dist = true
) => {
	let shortest_dist = null;
	if (!default_shortest_dist) {
		shortest_dist = 800;
	} else {
		shortest_dist = 100;
	}
	let city = null;
	for (const [key, value] of Object.entries(citiesList)) {
		const dist = distanceCalculator(
			lat,
			long,
			value.location[0],
			value.location[1]
		);
		if (dist < shortest_dist) {
			city = key;
			shortest_dist = dist;
		}
	}
	return city;
};

export const getSparesOrderDetails = async (leadId) => {
	const res = get(`spares-lead/orders/?lead_id=${leadId}`);
	return res ? res : null;
};

export const getCurrencySymbolCountryWise = () => getActiveCountryCode() === "MY" ? "RM" : "₹"

export const getActiveCountryCode = () => {
	if (typeof window !== undefined) {
        const countryCode = localStorage.getItem("country_code") || "IN";
        return countryCode
    }
	return "IN";
}
// Navigate to the lead details page based on the lead type
export const navigateToLeadDetailsPage = (lead, obj) => {
	const query = `${lead.mobile}?lead_id=${lead.lead_id}&lead_type=${lead.lead_type}&lead_type_id=${lead.lead_type_id}`;
	if (lead && lead.lead_type && sparesTypeLeadList.find(item=>item === lead.lead_type))
		obj.history.push(`/spares-lead/${query}`);
	else if (lead && lead.lead_type && accessoriesTypeLeadList.find(item=>item === lead.lead_type))
		obj.history.push(`/accessories-lead/${query}`);
	else
		obj.history.push(`/master-lead/${query}`)
}

export const getQueryParamsFromUrl = (props) => {
	const search = props.history.location.search;
	return new URLSearchParams(search);
}

export const checkIfActiveCountry = (testCountryCode) => {
	const countryCode = localStorage.getItem("country_code") || "";
	return countryCode === testCountryCode;
}

export const currencyConversion = (amount) => {
	if(checkIfActiveCountry("MY")) {
		return amount/18;
	}
	return amount;
}

export const getValidPhoneNumberLength = () => {
	if (checkIfActiveCountry("MY")) {
		return [9, 10, 11];
	}
	return [10];
}

export const getDispositionLeadType = (lead_type = "") => {
	const leadTypesForGeneralDiposition = ["general", "luxury", "service", "subscription", "accessories_retail_app", "kifayat"];
	const checkIfGeneralLeadApplicable = leadTypesForGeneralDiposition.find(item => item === lead_type);
	if (checkIfGeneralLeadApplicable) return "general";
	else return lead_type;
}

const packageTotals = (packageInTable) => {
	let total = 0;
	if (packageInTable && packageInTable.price_to_be_determined === 0) {
		packageInTable &&
			packageInTable.service_list.forEach((service) => {
				if (service.price_to_be_determined === 0) {
					total += service.total;
				}
			});
	}
	return total;
};

const servicesTotal = (servicesInTable) => {
	let total = 0;
	servicesInTable &&
		servicesInTable.forEach((service) => {
			if (service.price_to_be_determined === 0) {
				total += service.total;
			}
		});
	return total;
};

const calculateTotal = (serviceEstimateTableData) => {
	return packageTotals(serviceEstimateTableData.package) + servicesTotal(serviceEstimateTableData.service);
};

export const calculateFinalPrice = (serviceEstimateTableData) => {
	if (serviceEstimateTableData.go_app_money_used) {
		return (
			calculateTotal(serviceEstimateTableData) -
			serviceEstimateTableData.go_app_money_used +
			serviceEstimateTableData.slot_amount
		);
	} else {
		if (serviceEstimateTableData.discount_amnt) {
			if (serviceEstimateTableData.slot_amount) {
				return (
					calculateTotal(serviceEstimateTableData) +
					serviceEstimateTableData.slot_amount -
					serviceEstimateTableData.discount_amnt
				);
			} else {
				return (
					calculateTotal(serviceEstimateTableData) - serviceEstimateTableData.discount_amnt
				);
			}
		} else {
			if (serviceEstimateTableData.slot_amount) {
				return calculateTotal(serviceEstimateTableData) + serviceEstimateTableData.slot_amount;
			} else {
				return calculateTotal(serviceEstimateTableData);
			}
		}
	}
};

export const calculateTotalMilesDiscount = (serviceEstimateTableData) => {
    let total_discount = 0;
    if (serviceEstimateTableData && serviceEstimateTableData.service) {
      serviceEstimateTableData
        && serviceEstimateTableData.service.forEach(item => {
          total_discount += (item.subscription_discount || 0);
        })
    }

    if (serviceEstimateTableData && serviceEstimateTableData.package) {
      serviceEstimateTableData
        && serviceEstimateTableData.package.service_list
        && serviceEstimateTableData.package.service_list.forEach((service) => {
          total_discount += service.subscription_discount;
        });
    }
    return total_discount;
  }

// export const checkIfAssignmentTimeLimitReached = (timeFromLimit = 8, timeToLimit = 16) => {
// 	let timeStart = new Date().getHours();
// 	return !(timeStart >= timeFromLimit &&  timeStart < timeToLimit);
// }

function validateEmail(input) {
	if(typeof input !== 'string') return false;
	if(input.trim() === "") return true;
	var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (input.match(validRegex)) {
	  return true;
	} else {
	  return false;
	}
}

function validateName(name) {
	if(name === "" || !name) return false;
	if(name.toLowerCase().indexOf('customer') !== -1 || name.toLowerCase().indexOf('name') !== -1) return false;
	return true; 
}


export function validateInput(input, type) {
	switch(type){
		case 'email': 
			return validateEmail(input);
		case 'name': 
			return validateName(input);
		default: return true;
	}
}

export function createTimeSlots(timeStart, timeEnd, timeStep) {
    const startTime = moment(timeStart, "HH:mm").format("HH:mm");
    const endTime = moment(timeEnd, "HH:mm").format("HH:mm");
    let tempTime = startTime;
    let timeSlotsArr = [];
    while (tempTime < endTime) {
        const toTime = tempTime;
        tempTime = moment(tempTime, 'HH:mm').add(timeStep * 60, 'minutes').format('HH:mm');
        timeSlotsArr.push({ to: toTime, from: tempTime });
    }

}

export function scrollToPosition(id) {
	const section = document.querySelector( '#'+id);
	console.log(id, section);
	section && section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
}
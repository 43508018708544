import { createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger'
import moment from 'moment'
import reducers from '../Reducer/reducer';
import {checkIfKeyInLocalStorage, getDataFromLocalStorage, MultiSelectMapperFunction} from '../Shared/Methods';
const initialState = {
    leadList: { loader: false, data: [] },
    CEReportList: [],
    cities: [],
    cars: [],
    leadTypes: [],
    utmSources: [],
    leadSources: [],
    channels: [],
    leadStatuses: [],
    CRs: [],
    GIs: [],
    CEs: [],
    BDEs: [],
    BDMSs: [],
    ISMs: [],             //insurance salesmans
    L2s:[],
    Agents: [],
    insuranceCompanies: [],
    services: [],
    serviceDetails: [],
    serviceEstimateTableData: {},
    selectedAction: null,
    estimateRequiredTask: null,
    actionDetails: {},
    assignToFilter: [],
    assignToReportingFilter: [],
    token: localStorage.getItem('token'),
    leadQualties: [],
    showLoader: false,
    leadPaginator: { page_limit: 20, page: 1, total_leads: 0, page_limit_arr: [5, 10, 20, 50], search: null, callEffect: true },
    CEReportingPaginator: { page_limit: 20, page: 1, total_CE: 0, page_limit_arr: [5, 10, 20, 50], callEffect: true },
    leadTableSorting: { order_by: 'created_at', direction: 'desc' },
    filterData: {},
    CCbanner: null,
    TagFilterList: [],
    LeadActionList: [],
    GMVList: [],
    GroupByFilterList: [],
    paymentStatusList: [],
    sparesPartTypes: [],
    sparesBusinessTypes: [],
    sparesBrandTypeList: [],
    sparesSalesPersonList: [],
    sparesLeadStatusList: [],
    sparesCitiesList: [],
    sparesCarBrands: [],
    customerTypes: [],
    CEReportingFilterInfo: {
        assign_to: [],
        source_name: [],
        location: [],
        start_date: moment().subtract(7,'days').format('DD-MM-YYYY').concat(' 00:00 AM'),
        end_date:moment().format('DD-MM-YYYY').concat(' 11:59 AM'),
        range_calendar_start_date : moment().subtract(7,'days').toDate(),
        range_calendar_end_date : moment().toDate(),
        group_by: 'user_id',
        change_layout: 'user_id',
        lead_type: []
    },
    incomingCallInfo:{}
};

if (checkIfKeyInLocalStorage('filters')) {
    initialState.filterData = getDataFromLocalStorage('filters');
    if (!initialState.filterData.tags || !initialState.filterData.search_for_converted_by ||
        !initialState.filterData.lead_payment_status) {
        initialState.filterData = {
            ...initialState.filterData,
            tags: [],
            search_for_converted_by: false,
            lead_payment_status: null
        };
    }
    if(initialState.filterData.utm_source === null) {
        initialState.filterData = {...initialState.filterData, utm_source: []}
    }
} else {
    initialState.filterData = {
        source_name: [],
        location: [],
        status_name:[],
        lead_type:[],
        quality: null,
        assign_to: [],
        utm_source: [],
        is_language_barrier: false,
        start_date: moment().subtract(2, 'days').format('DD-MM-YYYY').concat(' 00:00 AM'),
        end_date:moment().add(1, 'days').format('DD-MM-YYYY').concat(' 11:59 AM'),
        range_calendar_start_date : moment().subtract(2, 'days').toDate(),
        range_calendar_end_date : moment().add(1, 'days').toDate(),
        short_date: null,
        tags: [],
        search_for_converted_by: false,
        lead_payment_status: null
    };

    initialState.CEReportingFilterInfo = {
      assign_to: [],
      source_name: [],
      location: []
    };
}

let store = createStore(reducers, initialState, applyMiddleware())

export default store

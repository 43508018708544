import { GET_LEAD_TYPES } from "../../Actions/ActionConstants";


function leadTypesReducer(state=null,action){
    switch (action.type) {
        case GET_LEAD_TYPES:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default leadTypesReducer
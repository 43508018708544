export const GET_LEADLIST='GET_LEADLIST'
export const GET_CITIES='GET_CITIES'
export const GET_CARS="GET_CARS"
export const GET_PREVIOUS_CUSTOMER_CARS="GET_PREVIOUS_CUSTOMER_CARS"
export const GET_LEAD_TYPES="GET_LEAD_TYPES"
export const GET_UTM_SOURCES="GET_UTM_SOURCES"
export const GET_LEAD_SOURCES="GET_LEAD_SOURCES"
export const GET_CHANNELS="GET_CHANNELS"
export const GET_LEAD_STATUSES="GET_LEAD_STATUSES"
export const GET_CRS="GET_CRS"
export const GET_GIs="GET_GIs"
export const GET_CEs="GET_CEs"
export const GET_BDEs="GET_BDEs"
export const GET_BDMSs = "GET_BDMSs"
export const GET_AGENTSs="GET_AGENTSs"
export const GET_ISMs="GET_ISMs"
export const GET_INSURANCE_COMPANIES="GET_INSURANCE_COMPANIES"
export const GET_SERVICES="GET_SERVICES"
export const GET_SERVICE_DETAILS="GET_SERVICE_DETAILS"
export const GET_ACTION_DETAILS="GET_ACTION_DETAILS"
export const GET_LEAD_QUALITY="GET_LEAD_QUALITY"
export const SET_SERVICE_ESTIMATE_TABLE_DATA="SET_SERVICE_ESTIMATE_TABLE_DATA"
export const UPDATE_SELECTED_ACTION_INDEX="UPDATE_SELECTED_ACTION_INDEX"
export const UPDATE_ESTIMATE_REQUIRED_TASK="UPDATE_ESTIMATE_REQUIRED_TASK"
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const SET_ASSIGN_TO_FILTER_DATA = "SET_ASSIGN_TO_FILTER_DATA"
export const SET_TOKEN="SET_TOKEN"
export const TOGGLE_LOADER="TOGGLE_LOADER"
export const LEAD_TABLE_PAGINATOR="LEAD_TABLE_PAGINATOR"
export const LEAD_TABLE_SORTING_ORDER="LEAD_TABLE_SORTING_ORDER"
export const GET_L2s="GET_L2s"
export const SET_CC_BANNER_INFO="SET_CC_BANNER_INFO"
export const SET_LEAD_ACTION_LIST="SET_LEAD_ACTION_LIST"
export const SET_CE_REPORTING_FILTER="SET_CE_REPORTING_FILTER"
export const SET_CE_REPORTING_LIST="SET_CE_REPORTING_LIST"
export const SET_CE_REPORTING_PAGINATOR="SET_CE_REPORTING_PAGINATOR"
export const SET_TAG_FILTERS="SET_TAG_FILTERS"
export const SET_GMV_LIST = "SET_GMV_LIST"
export const SET_GROUP_BY_FILTER = "SET_GROUP_BY_FILTER"
export const SET_ASSIGN_TO_REPORT_FILTER = "SET_ASSIGN_TO_REPORT_FILTER"
export const SET_PAYEMNT_STATUS_FILTER_LIST = "SET_PAYEMNT_STATUS_FILTER_LIST"
export const SET_SPARES_TYPE_PARTS_LIST = "SET_SPARES_TYPE_PARTS_LIST"
export const SET_SPARES_BUSINESS_TYPE_LIST = "SET_SPARES_BUSINESS_TYPE_LIST"
export const SET_SPARES_BRAND_TYPE_LIST = "SET_SPARES_BRAND_TYPE_LIST"
export const SET_SPARES_SALES_PERSON_LIST = "SET_SPARES_SALES_PERSON_LIST"
export const SET_SPARES_LEAD_STATUS_LIST = "SET_SPARES_LEAD_STATUS_LIST"
export const SET_SPARES_CITIES_LIST = "SET_SPARES_CITIES_LIST"
export const SET_SPARES_CAR_BRANDS_LIST = "SET_SPARES_CAR_BRANDS_LIST"
export const SET_CATEGORY_TEMPLATE_TEXT = "SET_CATEGORY_TEMPLATE_TEXT"
export const SET_CATEGORY_TEMPLATE_TEXT_COPY = "SET_CATEGORY_TEMPLATE_TEXT_COPY"
export const SET_INCOMING_CALL_INFO = "SET_INCOMING_CALL_INFO"
export const SET_SPARES_CUSTOMER_TYPE_LIST = "SET_SPARES_CUSTOMER_TYPE_LIST"
export const SET_AMC_PURCHASE_STATUS = "SET_AMC_PURCHASE_STATUS"
export const SET_PRODUCT_REQUIREMENT_TAGS = "SET_PRODUCT_REQUIREMENT_TAGS"
export const SET_SELECTED_LEAD_LIST = "SET_SELECTED_LEAD_LIST"
export const GET_PACKAGE_BOOSTERS = "GET_PACKAGE_BOOSTERS"
export const GET_ARRIVAL_MODES = "GET_ARRIVAL_MODES"
export const SET_LEAD_ESTIMATE_LOADER = "SET_LEAD_ESTIMATE_LOADER"
export const GET_ALL_LEAD_STATUSEs = "GET_ALL_LEAD_STATUSEs"
export const GET_SPECIAL_OFFERS_AND_DEALS = "GET_SPECIAL_OFFERS_AND_DEALS"
export const GET_MILES_SAVING_COUPONS_DATA = "GET_MILES_SAVING_COUPONS_DATA"
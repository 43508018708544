import { UPDATE_SELECTED_ACTION_INDEX } from "../../Actions/ActionConstants";

function selectedActionReducer(state=null,action){
    switch (action.type) {
        case UPDATE_SELECTED_ACTION_INDEX:
                if(action.data)
                {return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default selectedActionReducer
import {SET_SPARES_TYPE_PARTS_LIST} from "../../Actions/ActionConstants";

const PartTypeListReducer = (state = null, action) => {
    if (action.type === SET_SPARES_TYPE_PARTS_LIST) {
        return action.data;
    } else {
        return state;
    }
};
export default PartTypeListReducer

import { GET_BDMSs } from "../../Actions/ActionConstants";

function BDMSsReducer(state = null, action) {
    switch (action.type) {
        case GET_BDMSs:
            if (action.data) { return action.data; }
            else { return state }
        default:
            return state;
    }
}
export default BDMSsReducer
import moment from 'moment';
import listener from './CheckAuth';
import { updateLoginStatusOnServer } from "../Shared/Methods";

let baseUrl = process.env.REACT_APP_API_URL;
const partRocketBaseUrl = process.env.REACT_APP_PART_ROCKET;
const spareBaseUrl = process.env.REACT_APP_SPARES;
const accToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxNjUyMDgwODQyOTkiLCJpYXQiOjE2NTIwODA4NDMuMCwiZXhwIjoxNjU0NjcyODQzLjAsImF1ZCI6IjMiLCJqdGkiOiJiNGJjM2NhZjVkMWVhOTlkYzk2YjQzM2NjYzQzMDI0ZTAyM2I0MGM2YjQ5ZjExN2JjMDk5OGY2MWU3ZDI1ZjM2MTU1YWU5ZDIxNjE2ZTc5NSIsIm5iZiI6MTY1MjA4MDg0My4wLCJzY29wZXMiOltdfQ.YKfHIueZZCbZn0KE59YgbPd9xmcNe1fJbb9yqsVNoWJgYrwSjaOVkJoVhO3LIC31JKKFSxk2t7bz63-smiAJTeOBsJ_ieVxk81PGidI4oxf08Vx068THWOX8WqWlOka92vkuWk8z-LAn3AMqz_jPZaCdLO0mL9ze07dq9EoDCOnAwktSq41XEIpBd23o1CRgFlIzdLBO6GHM6oBjL67Bsd0Zp4dRSJjrYlTDyNi_nbKywRDCOTk3VS872Qtf5Id-i_jXkkkXtw3R6N-wuXbxMpDvzrLraGPG7La8Vjhu6rr64jUOHPrPX6FtjljzX8eEwZ3BvC0mp-cs8kD89ir5X_x39JXu4Gn5YnD5nNNPDsXKKaRaF-l9W3mo6RsWNy2vnwQiA-hF8Ay3xVBg_EwOfPAjA7GK0Pt9RQKDz-AYzcVUQn7zB0p-0wN-w7S85MO0lvlYmCUb6Yx5pywrNUFDguYAE0BLcOxd14l_Osu4ZwzGEYYMQZTxurCocdsWphU1iOcVCjYRNneZ0l9INwy77RvH8egiV7VMJ1Fv_1FC3157F4K4fyvVwH9hBa4wcAsGRWAvDah5u0vks_4uURUHX_UWzKM57aOaEw75YCZVNzmXyQHc8celpJkTsauRb42lJqHmhNUKBGES0mCCZCRCwregDllhwD7YnZMXvypl2G8";

function getBaseUrlCountryWise(){
    if (typeof window !== undefined) {
        const countryCode = localStorage.getItem("country_code") || "";
        return countryCode === "MY" ? process.env.REACT_APP_API_URL_MALASIA : process.env.REACT_APP_API_URL;
    }   
}
const workforceAppToken = process.env.REACT_APP_WORKFORCE_TOKEN;

export function get(url, data = "", externalBaseUrl = "", token_origin = "") {
    let tokenValue = "";
    if (token_origin === "accessories") {
        tokenValue = process.env.REACT_APP_ACCESSORIES || accToken;
    } else {
        tokenValue = localStorage.getItem('token');
    }
    let token = "Bearer " + tokenValue;
    let baseUrl = externalBaseUrl || getBaseUrlCountryWise();
    
    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': token
    };
    const options = {
        method: "GET",
        headers: headers,
    };
    if (localStorage.getItem('token')) {
        return fetch((baseUrl + url + data), options).then(res => {
            if (res.status === 401 || res.status === 406) {
                if (listener.active && listener.listenerFn) {
                    updateLoginStatusOnServer({ is_logged_in: false, activity: "login" });
                    if (res.status === 401) {
                        listener.listenerFn();
                    } else {
                        listener.listenerFn("You are using the older version of the application. Please refresh and login again.");
                    }
                    return new Promise((resolve, reject) => {
                        reject("unauthorized");
                    });
                }
            } else {
                return res.json();
            }
        }, err => err);
    } else {
        if (listener.active && listener.listenerFn) {
            listener.listenerFn();
        }
        return new Promise((resolve, reject) => {
            reject("unauthorized");
        });
    }
}

export function getPartRocket(param) {
    const url = `${partRocketBaseUrl}${param}`;
    
    const headers = {
        'Accept': 'application/json; ',
        'Content-Type': 'application/json'
    };
    const options = {
        method: "GET",
        headers: headers,
    };

        return fetch(url, options).then(res => {
            return res.json();
        }, err => err);
}

export function getSpares(param,token=null) {
    // const url = `${spareBaseUrl}${param}`;
    const url = `${getBaseUrlCountryWise()}${param}`;
    let mlToken = "Bearer " + localStorage.getItem('token');
    const tokenId = `${token}`
    
    let headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': mlToken
    };
    if(token){
        headers={
            ...headers,
            customer:tokenId
        }
    }
    const options = {
        method: "GET",
        headers: headers,
    };

        return fetch(url, options).then(res => {
            return res.json();
        }, err => err);
}
 
export function postSpares(param,data,token=null) {
    const tokenId = `${token}`
    let mlToken = "Bearer " + localStorage.getItem('token');
    // const url = `${spareBaseUrl}${param}`;
    const url = `${getBaseUrlCountryWise()}${param}`;
    

    let headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': mlToken
    }
    if(token){
        headers={
            ...headers,
            customer:tokenId
        }
    }
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    }

    return fetch(url, options)
        .then(res => {
            return res.json();
        }, err => err);

}
export function deleteSpares(param,data,token=null) {
    const tokenId = `${token}`
    // const url = `${spareBaseUrl}${param}`;
    const url = `${getBaseUrlCountryWise()}${param}`;
    let mlToken = "Bearer " + localStorage.getItem('token');

    let headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': mlToken
    }
    if(token){
        headers={
            ...headers,
            customer:tokenId
        }
    }
    const options = {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(data),
    }

    return fetch(url, options)
        .then(res => {
            return res.json();
        }, err => err);

}


export function getInfoFromWorkForceApp(url, orderId = "newOrder", type = "") {
    const options = {
        method: "GET",
        headers: {
            'Accept': 'application/json; version=1.09',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + workforceAppToken
        }
    };
    if (workforceAppToken) {
        return fetch((url), options).then(async (response) => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                const new_file = new Blob([await response.text()], { type: "application/pdf'" });
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                const url = window.URL.createObjectURL(new_file);
                a.href = url;
                a.download = `${type}_${orderId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
                return true;
            }
        }, err => {
            return err;
        });
    }
}

export function getExcel(url) {
    let token = "Bearer " + localStorage.getItem('token');
    let baseUrl = getBaseUrlCountryWise();

    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': token
    };
    const options = {
        method: "GET",
        headers: headers,
    };
    return fetch((baseUrl + url), options).then(async (res) => {
        const new_file = new Blob([await res.text()], { type: "text/csv'" });
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(new_file);
        a.href = url;
        a.download = `leads_${moment().format("DD_MM_YYYY_hh_mm_ss_a")}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
    }, err => err);
}

export function callToCustomer(url) {
    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json'
    }
    const options = {
        method: "GET",
        headers: headers,
    }
    return fetch(url, options).then(res => res.json(), err => err);
}

export function preVerifyCallerId(agent_number, customer_number) {
    let baseUrl = getBaseUrlCountryWise();
    const url = baseUrl + 'make-register/';
    const data = {
        agent_number,
        customer_number
    };
    const headers = {
        'Content-Type': 'application/json'
    }
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    }
    return fetch(url, options).then(res => res.json(), err => err);
}

export function post(url, data, is_login = false) {
    let token = "Bearer " + localStorage.getItem('token');
    let baseUrl = getBaseUrlCountryWise();

    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': token
    }
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    }
  
    if (localStorage.getItem('token') || is_login) {
        return fetch(baseUrl + url, options).then(res => {
            if (res.status === 401) {
                if (listener.active && listener.listenerFn) {
                    updateLoginStatusOnServer({ is_logged_in: false, activity: "login" });
                    listener.listenerFn();
                    return new Promise((resolve, reject) => {
                        reject("unauthorized");
                    });
                }
            } else {
                return res.json();
            }
        }, err => err);
    } else {
        if (listener.active && listener.listenerFn) {
            listener.listenerFn();
        }
        return new Promise((resolve, reject) => {
            reject("unauthorized");
        });
    }
}

export function put(url, data) {
    let token = "Bearer " + localStorage.getItem('token');
    let baseUrl = getBaseUrlCountryWise();

    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': token
    }
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
    }
    if (localStorage.getItem('token')) {
        return fetch(baseUrl + url, options).then(res => {
            if (res.status === 401) {
                if (listener.active && listener.listenerFn) {
                    updateLoginStatusOnServer({ is_logged_in: false, activity: "login" });
                    listener.listenerFn();
                    return new Promise((resolve, reject) => {
                        reject("unauthorized");
                    });
                }
            } else {
                return res.json();
            }
        }, err => err);
    } else {
        if (listener.active && listener.listenerFn) {
            listener.listenerFn();
        }
        return new Promise((resolve, reject) => {
            reject("unauthorized");
        });
    }
}

export function patch(url, data) {
    let token = "Bearer " + localStorage.getItem('token');
    let baseUrl = getBaseUrlCountryWise();

    const headers = {
        'Accept': 'application/json; version=1.09',
        'Content-Type': 'application/json',
        'Authorization': token
    }
    const options = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
    }
    return fetch(baseUrl + url, options).then(res => res.json(), err => err);
}

export function bulkUpload(url, fileFormData) {
    let token = "Bearer " + localStorage.getItem('token');
    let baseUrl = getBaseUrlCountryWise();

    const headers = {
        'Accept': 'application/json; version=1.09',
        'Authorization': token
    };
    const options = {
        method: "POST",
        headers: headers,
        body: fileFormData,
    };
    return fetch(baseUrl + url, options).then(res => res.json(), err => err);
}

export function pincodeValidate(value){
    const options = {
        method: "GET",
    };
    let url = `https://spares-mp.gomechanic.app/delivery-time-check/?warehouse=gm_DEL&pincode=${value}`
    return fetch(url, options).then(res => res.json(), err => err);
}

export function getAgentStatus (){

}

export function setAgentStatus (){
    
}
import { callToCustomer, preVerifyCallerId } from "../Services/FetchWrapper"
import { getUserDataFromLocal } from "../Shared/Methods"
import ValidityJSON from '../Shared/ValidityJSON/Validity.json';

export async function callCustomer(customerNumber) {
    const DID_number = getUserDataFromLocal().deskphone ? getUserDataFromLocal().deskphone : '01206850583';
    await preVerifyCallerId(DID_number, customerNumber)
        .catch((err) => console.log(err));
    await callToCustomer(`https://leads.gomechanic.app/api/click_to_call?calling_party_a=${getUserDataFromLocal().mobile}&calling_party_b=${customerNumber}&deskphone=${DID_number}&email=${ValidityJSON.DID_mapping[DID_number].email}&password=${ValidityJSON.DID_mapping[DID_number].password}`)
        .then(() => {
            console.log('Call Initiated')
        }).catch((error) => {
            console.log(error);
        });
}

export async function callCustomerByBDE(customerNumber) {
    const DID_number = getUserDataFromLocal().deskphone ? getUserDataFromLocal().deskphone : '01206397184';
    await preVerifyCallerId(DID_number, customerNumber)
        .catch((err) => console.log(err));
    await callToCustomer(`https://leads.gomechanic.app/api/click_to_call?calling_party_a=${getUserDataFromLocal().mobile}&calling_party_b=${customerNumber}&deskphone=${DID_number}&email=${ValidityJSON.DID_mapping[DID_number].email}&password=${ValidityJSON.DID_mapping[DID_number].password}&call_from_did=1`)
        .then(() => {
            console.log('Call Initiated')
        }).catch((error) => {
            console.log(error);
        });
}

import { GET_ALL_LEAD_STATUSEs } from "../../Actions/ActionConstants";

function AllLeadStatusesReducer(state = null, action) {
    switch (action.type) {
        case GET_ALL_LEAD_STATUSEs:
            if (action.data) { return action.data; }
            else { return state }
        default:
            return state;
    }
}
export default AllLeadStatusesReducer
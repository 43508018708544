import { GET_PACKAGE_BOOSTERS } from "../../Actions/ActionConstants";

function packageBoostersReducer(state = null, action) {
    switch (action.type) {
        case GET_PACKAGE_BOOSTERS:
            if (action.data) {
                return action.data;
            }
            else { return null }
        default:
            return state;
    }
}
export default packageBoostersReducer
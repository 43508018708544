import { GET_BDEs } from "../../Actions/ActionConstants";

function BDEsReducer(state=null,action){
    switch (action.type) {
        case GET_BDEs:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default BDEsReducer
import {SET_CE_REPORTING_PAGINATOR} from "../../Actions/ActionConstants";

const CEReportingPaginatorReducer = (state = null, action) => {
    if (action.type === SET_CE_REPORTING_PAGINATOR) {
        return action.data;
    } else {
        return state;
    }
};
export default CEReportingPaginatorReducer

import { combineReducers } from "redux";
import leadListReducer from "./LeadList/leadListReducer";
import CitiesReducer from "./Common/cities";
import carReducer from "./MasterLead/carReducer";
import leadTypesReducer from "./Common/leadTypes";
import utmSourcesReducer from "./Common/utmSources";
import leadSourcesReducer from "./Common/leadSources";
import channelReducer from "./Common/channels";
import leadStatusesReducer from "./Common/leadStatuses";
import CRsReducer from "./Common/CRsReducer";
import GIsReducer from "./Common/GIsReducer";
import CEsReducer from "./Common/CEsReducer";
import BDEsReducer from "./Common/BDEsReducer";
import BDMSsReducer from "./Common/BDMSsReducer";
import AgentsReducer from "./Common/AgentsReducer";
import ISMsReducer from "./Common/ISMsReducer";
import LeadQualityReducer from "./Common/LeadQualityReducer";
import InsuranceCompaniesReducer from "./Common/companiesReducer";
import serviceEstimateReducer from "./ServiceEstimate/serviceEstimateReducer";
import serviceDetailsReducer from "./ServiceEstimate/serviceDetailsReducer";
import serviceEstimateTableDataReducer from "./ServiceEstimate/serviceEstimateTableDataReducer";
import selectedActionReducer from "./MasterLead/selectedActionIndexReducer";
import estimateTaskReducer from "./MasterLead/estimateTaskReducer";
import actionDetailsReducer from "./Actions/actionDetailsReducer";
import filterDetailsReducer from './LeadFilter/LeadFilterReducer';
import assignToDataReducer from './LeadFilter/LeadAssignToFilter';
import tokenReducer from "./Common/TokenReducer";
import LoaderReducer from "./Common/LoaderReducer";
import LeadTablePaginatorReducer from './Common/LeadTablePaginatorReducer';
import LeadTableSortingReducer from './Common/LeadTableSortingReducer';
import L2sReducer from "./Common/L2sReducer";
import CEReportingFilterReducer from "./Common/CEReportingFilterReducer";
import CCBannerReducer from './Common/CCBannerReducer';
import LeadActionReducer from "./Common/LeadActionReducer";
import CEReportListReducer from './Common/CEReportListReducer';
import CEReportingPaginatorReducer from "./Common/CEReportingPaginatorReducer";
import TagFilterListReducer from "./Common/TagFilterListReducer";
import GMVListReducer from "./Common/GMVReducer";
import GroupByFilterListReducer from "./Common/groupByFilterReducer";
import AssignToReportingFilterReducer from "./Common/AssignToReportingFilter";
import PaymentStatusListReducer from "./Common/paymentStatusListReducer";
import PartTypeListReducer from "./Spares/PartTypesListReducer";
import BusinessTypeListReducer from "./Spares/BusinessTypeListReducer";
import BrandTypeListReducer from "./Spares/BrandTypeListReducer";
import SalesPersonListReducer from "./Spares/SalesPersonListReducer";
import LeadStatusListReducer from "./Spares/LeadStatusListReducer";
import CitiesListReducer from "./Spares/CitiesListReducer";
import CarBrandsListReducer from "./Spares/CarBrandsListReducers";
import CustomerTypesListReducer from "./Spares/CustomerTypesListReducer";
import categoryTemplateTexts from "./Common/categoryTemplateTexts";
import incomingCallInfo from "./Common/IncomingCallInfo";
import amcPurchaseStatus from './Common/AmcPurchaseStatus';
import productRequirementTagsReducer from './Common/productRequirementTagsReducer';
import selectedLeadListReducer from "./LeadList/selectedLeadListReducer";
import packageBoostersReducer from "./ServiceEstimate/packageBoostersReducer";
import previousCustomerCarsReducer from './Common/previousCustomerCarsReducer';
import ArrivalModesReducer from './Common/ArrivalModesReducer';
import leadServiceEstimateLoaderReducer from "./ServiceEstimate/leadServiceEstimateLoaderReducer";
import allLeadStatusesReducer from './Common/AllLeadStatusesReducer';
import specialOffersAndDeals from './Common/SpecialOffersAndDeals';
import MilesSavingCouponReducer from "./Common/MilesSavingCouponReducer";

const reducers = combineReducers({
    leadList:leadListReducer,
    cities:CitiesReducer,
    cars:carReducer,
    previousCustomerCars: previousCustomerCarsReducer,
    leadTypes:leadTypesReducer,
    utmSources:utmSourcesReducer,
    leadSources:leadSourcesReducer,
    channels:channelReducer,
    leadStatuses:leadStatusesReducer,
    CRs:CRsReducer,
    GIs:GIsReducer,
    CEs:CEsReducer,
    BDEs:BDEsReducer,
    BDMSs: BDMSsReducer,
    Agents: AgentsReducer,
    ISMs:ISMsReducer,
    L2s:L2sReducer,
    leadQualties:LeadQualityReducer,
    insuranceCompanies:InsuranceCompaniesReducer,
    services: serviceEstimateReducer,
    serviceDetails: serviceDetailsReducer,
    serviceEstimateTableData: serviceEstimateTableDataReducer,
    selectedAction:selectedActionReducer,
    estimateRequiredTask:estimateTaskReducer,
    actionDetails: actionDetailsReducer,
    filterData: filterDetailsReducer,
    assignToFilter:assignToDataReducer,
    token:tokenReducer,
    showLoader:LoaderReducer,
    leadPaginator: LeadTablePaginatorReducer,
    leadTableSorting: LeadTableSortingReducer,
    CEReportingFilterInfo: CEReportingFilterReducer,
    CCbanner: CCBannerReducer,
    LeadActionList: LeadActionReducer,
    CEReportList: CEReportListReducer,
    CEReportingPaginator: CEReportingPaginatorReducer,
    TagFilterList: TagFilterListReducer,
    GMVList: GMVListReducer,
    GroupByFilterList: GroupByFilterListReducer,
    assignToReportingFilter: AssignToReportingFilterReducer,
    paymentStatusList: PaymentStatusListReducer,
    sparesPartTypes: PartTypeListReducer,
    sparesBusinessTypes: BusinessTypeListReducer,
    sparesBrandTypeList: BrandTypeListReducer,
    sparesSalesPersonList: SalesPersonListReducer,
    sparesLeadStatusList: LeadStatusListReducer,
    sparesCitiesList: CitiesListReducer,
    sparesCarBrands : CarBrandsListReducer,
    customerTypes: CustomerTypesListReducer,
    categoryTemplateTexts: categoryTemplateTexts,
    incomingCallInfo:incomingCallInfo,
    amcPurchaseStatus: amcPurchaseStatus,
    productRequirementTags: productRequirementTagsReducer,
    selectedLeadList: selectedLeadListReducer,
    packageBoosters: packageBoostersReducer,
    arrivalModes: ArrivalModesReducer,
    leadServiceEstimateLoader: leadServiceEstimateLoaderReducer,
    allLeadStatuses: allLeadStatusesReducer,
    specialOffersAndDeals: specialOffersAndDeals,
    milesSavingCouponData: MilesSavingCouponReducer,
  });

  export default reducers;

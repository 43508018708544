import { GET_ISMs } from "../../Actions/ActionConstants";

function ISMsReducer(state=null,action){
    switch (action.type) {
        case GET_ISMs:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default ISMsReducer
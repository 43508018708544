import { GET_INSURANCE_COMPANIES } from "../../Actions/ActionConstants";

function InsuranceCompaniesReducer(state=null,action){
    switch (action.type) {
        case GET_INSURANCE_COMPANIES:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default InsuranceCompaniesReducer
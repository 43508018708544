import { SET_CC_BANNER_INFO } from "../../Actions/ActionConstants";

const CCBannerReducer = (state = null, action) => {
    switch (action.type) {
        case SET_CC_BANNER_INFO:
            return action.data
        default:
            return state;
    }
}
export default CCBannerReducer
import { GET_CARS } from "../../Actions/ActionConstants";

function carReducer(state=null,action){
    switch (action.type) {
        case GET_CARS:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default carReducer
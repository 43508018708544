import { GET_LEAD_STATUSES } from "../../Actions/ActionConstants";

function leadStatusesReducer(state=null,action){
    switch (action.type) {
        case GET_LEAD_STATUSES:
                if(action.data){return action.data;}
                else{return state}
        default:
            return state;
    }
}
export default leadStatusesReducer
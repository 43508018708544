import { GET_ARRIVAL_MODES } from "../../Actions/ActionConstants";

function ArrivalModesReducer(state = null, action) {
    switch (action.type) {
        case GET_ARRIVAL_MODES:
            if (action.data) { return action.data; }
            else { return state }
        default:
            return state;
    }
}
export default ArrivalModesReducer
import {SET_TAG_FILTERS} from "../../Actions/ActionConstants";

const TagFilterListReducer = (state = null, action) => {
    if (action.type === SET_TAG_FILTERS) {
        return action.data;
    } else {
        return state;
    }
};
export default TagFilterListReducer

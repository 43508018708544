import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/globalCSS.css'
import './Loader.css'

const Loader = (props) => {
    console.log("______", props.showLoader);
    return (
        <div className={`overlay overlay-container`} style={{display:props.showLoader && props.showLoader.open?"flex":"none"}}>
            <div className='overlay-headline'>{props.showLoader && props.showLoader.text ? props.showLoader.text : "Please wait..."}</div>
            <div className="loader"></div>
        </div>
    )
}

export default connect((state) => {
    return {
        showLoader:state.showLoader
    }
})(Loader)
import { SET_FILTER_DATA } from "../../Actions/ActionConstants";

function filterDataReducer(state = null, action) {
    switch (action.type) {
        case SET_FILTER_DATA:
            if (action.data) { return action.data; }
            else { return null }
        default:
            return state;
    }
}
export default filterDataReducer
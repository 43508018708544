import React, { Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from './privateRoute';

const LeadList = lazy(() => import('../Components/LeadList/leadList'));
const MLWrapper = lazy(() => import('../Components/MasterLead/MasterLead'));
const notFound = lazy(() => import('../notFound/notFound'));
const Login = lazy(() => import('../Components/Login/Login'));
const CEReporting = lazy(() => import('../Components/CEReporting/CEReporting'));
const UploadStatusReport = lazy(() => import('../Components/UploadStatusReport/uploadStatusReport'));
const SparesLeadWrapper = lazy(() => import('../Components/Spares'));
const AccessoriesLeadWrapper = lazy(() => import('../Components/AccessoriesLead'));

const Imageupload = lazy(()=> import ('../Components/ImageUpload/components/ImageUpload/ImageUpload'))

const CreateVendor = lazy(()=> import('../Components/FullFillment/VendorsInfo/CreateVendor/CreateVendor'))
const VendorDetails = lazy(()=> import('../Components/FullFillment/VendorsInfo/VendorDetails/VendorDetails'))
const VendorBiddingPanel = lazy(()=>import('../Components/FullFillment/VendorBiddingPanel/VendorBiddingPanel'))
const GomechanicBiddingPanel = lazy(()=>import('../Components/FullFillment/GomechanicBiddingPanel/GomechanicBiddingPanel'))
const OrderSummary = lazy(()=>import('../Components/FullFillment/OrderSummary/OrderSummary'))




const RoutingComponent = (props) => {
  return (<Suspense fallback={<div> </div>}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/lead-list" component={LeadList} />
        <PrivateRoute exact path="/master-lead/:id" component={MLWrapper} />
        <PrivateRoute exact path="/CE-report" component={CEReporting} />
        <PrivateRoute exact path="/upload_status/:id" component={UploadStatusReport} />
        <PrivateRoute exact path="/spares-lead/:id" component={SparesLeadWrapper} />
        <PrivateRoute exact path="/accessories-lead/:id" component={AccessoriesLeadWrapper} />

        <PrivateRoute exact path="/image-upload" component={Imageupload} />

        <Route exact path="/create-vendor" component={CreateVendor} />
        <Route exact path="/vendor-details" component={VendorDetails} />
        <Route exact path="/vendor-bidding-panel" component={VendorBiddingPanel} />
        <Route exact path="/gomechanic-bidding-panel" component={GomechanicBiddingPanel} />
        <Route exact path="/order-summary" component={OrderSummary} />
        
        <Route component={notFound} />
      </Switch>
    </Router>
  </Suspense>)
}

export default RoutingComponent

import { LEAD_TABLE_PAGINATOR } from "../../Actions/ActionConstants";

const LeadTablePaginatorReducer = (state = null, action) => {
    switch (action.type) {
        case LEAD_TABLE_PAGINATOR:
            return action.data
        default:
            return state;
    }
}

export default LeadTablePaginatorReducer;
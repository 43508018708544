import { GET_SERVICES } from "../../Actions/ActionConstants";

function serviceEstimateReducer(state=[], action){
    switch (action.type) {
        case GET_SERVICES:
            return action.data;
        default:
            return state;
    }
}
export default serviceEstimateReducer
import { SET_ASSIGN_TO_REPORT_FILTER } from "../../Actions/ActionConstants";

const AssignToReportingFilterReducer = (state = null, action) => {
    if (action.type === SET_ASSIGN_TO_REPORT_FILTER) {
        return action.data;
    } else {
        return state;
    }
};
export default AssignToReportingFilterReducer

import { GET_SERVICE_DETAILS } from "../../Actions/ActionConstants";

function serviceDetailsReducer(state=[], action){
    switch (action.type) {
        case GET_SERVICE_DETAILS:
            return action.data;
        default:
            return state;
    }
}
export default serviceDetailsReducer
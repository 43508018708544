import { SET_SELECTED_LEAD_LIST } from "../../Actions/ActionConstants";

function selectedLeadListReducer(state = [], action) {
    switch (action.type) {
        case SET_SELECTED_LEAD_LIST:
            if (action.data) { return action.data; }
            else { return state }
        default:
            return state;
    }
}
export default selectedLeadListReducer

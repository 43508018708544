import { SET_PRODUCT_REQUIREMENT_TAGS } from "../../Actions/ActionConstants";

function productRequirementTagsReducer(state = null, action) {
    switch (action.type) {
        case SET_PRODUCT_REQUIREMENT_TAGS:
            if (action.data) {
                return action.data;
            }
            else { return null }
        default:
            return state;
    }
}
export default productRequirementTagsReducer
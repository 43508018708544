import { SET_LEAD_ACTION_LIST } from "../../Actions/ActionConstants";

const LeadActionReducer = (state = null, action) => {
    if (action.type === SET_LEAD_ACTION_LIST) {
        return action.data;
    } else {
        return state;
    }
};
export default LeadActionReducer

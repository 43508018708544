import { SET_SERVICE_ESTIMATE_TABLE_DATA } from "../../Actions/ActionConstants";


function serviceEstimateTableDataReducer(state={}, action){
    switch (action.type) {
        case SET_SERVICE_ESTIMATE_TABLE_DATA:
            const { data } = action
            return Object.keys(data).length ?{...state, ...data}: data;
        default:
            return state;
    } 
}

export default serviceEstimateTableDataReducer

import React from 'react';
import './App.css';
import { Provider } from "react-redux";
import store from "./store/configureStore";
import RoutingComponent from './routes/routes'
import './Styles/globalCSS.css'
import './Styles/textButton.css'
import Loader from './Shared/Loader/Loader';
// import MultiSelect from './Shared/multiSelect/MultiSelect';

function App() {
  return (
    <Provider store={store} >
      <Loader/>
      {/* <MultiSelect placeholder="Select Done List"/> */}
      <RoutingComponent />
    </Provider>
  );

}

export default App;
